import request from '@/utils/request'

//创建激活码
export function createCode(data){
    return request({
        url: Config.url202+'/create',
        method: 'post',
        data:data
    })
}

//搜索激活码（分页）
export function searchCode(data){
    return request({
        url:Config.url202+'/search',
        method:'post',
        data:data
    })
}

//禁用激活码
export function disableCode(data){
    return request({
        url:Config.url202+'/disable',
        method:'post',
        data:data
    })
}

//启用激活码
export function enableCode(data){
    return request({
        url:Config.url202+'/enable',
        method:'post',
        data:data
    })
}

export function getProduct(){
    return request({
        url:Config.product,
        method:'get',
    })
}