<template>
  <div>
    <div class="title">
      <div class="viewName">激活码管理</div>
      <el-button v-show='show' type="primary" @click="createActivationCode"
        >创建激活码</el-button
      >
    </div>
    <div class="content">
      <el-form :inline="true" :model="queryParam" ref="queryParam">
        <el-form-item label="产品名称" prop="productName" class="contentItem">
          <el-select
            v-model="queryParam.productName"
            placeholder="请选择产品名称"
            @change="queryList()"
          >
            <el-option
              v-for="item in productList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              >{{ item.label }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item prop="enabledOnly" class="contentCheckbox">
          <el-checkbox v-model="queryParam.enabledOnly" @change="queryList()"
            >仅显示已启用的</el-checkbox
          >
        </el-form-item>
        <el-form-item prop="keyWord" class="contentInput">
          <el-input
            @keyup.enter.native="queryList"
            v-model="queryParam.keyWord"
            prefix-icon="el-icon-search"
            placeholder="搜索"
            class="itemInput"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="mytablediv">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#F1F4F9' }"
        >
          <el-table-column
            align="center"
            prop="productName"
            label="产品名称"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="keyData"
            label="激活码"
            width="150"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="enabled"
            label="状态"
            filter-placement="bottom-end"
          >
            <template slot-scope="scope">
              <el-tag
                :class="scope.row.enabled === true ? 'success' : 'danger'"
                size="medium"
                disable-transitions
                >{{ scope.row.enabled == true ? "已启用" : "已禁用" }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="projectInfo"
            label="项目信息"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="licenses[0].type"
            label="许可证类型"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="supportedDeviceType"
            width="100"
            label="支持设备"
          ></el-table-column>
          <el-table-column align="center" prop="bindLimited" label="绑定权限">
            <template slot-scope="scope">
              <span>{{ scope.row.bindLimited === true ? "是" : "否" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="permissionKeys.length"
            label="权限"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="countOfLicense"
            label="许可证总量"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="countOfUsedLicenses"
            label="已激活"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="countOfExpiredLicenses"
            label="已过期"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="countOfValidLicenses"
            label="可用数量"
          ></el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <div style="display: inline-flex">
                <div @click="lookUp(scope.row)">查看</div>
                <div
                  :class="scope.row.enabled === true ? 'ban' : 'disabledBan'"
                  @click="ban(scope.row)"
                >
                  禁用
                </div>
                <div
                  :class="
                    scope.row.enabled === true ? 'disabledStart' : 'start'
                  "
                  @click="start(scope.row)"
                >
                  启用
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <my-pages
          :total="total"
          :page-size="pageSize"
          @handleSizeChangeSub="handleSizeChangeFun"
          @handleCurrentChangeSub="handleCurrentChangeFun"
        ></my-pages>
      </div>
      <el-dialog
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
        class="dialogContainer"
        @open="resetFields('form')"
      >
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item
            label="产品名称："
            label-width="145px"
            prop="productName"
          >
            <el-select v-model="form.productName" class="formItem" disabled>
              <el-option label="CWAVE" value="CWAVE"></el-option>
              <el-option label="CDAS" value="CDAS"></el-option>
              <el-option label='ENGINEER' value='ENGINEER'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="激活码：" label-width="145px" prop="keyData">
            <el-input
              :disabled="view"
              v-model="form.keyData"
              class="formItem"
              placeholder="输入期望的激活码，长度不低于8位，如留空，则由系统创建"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="创建者信息："
            label-width="145px"
            prop="operatorInfo"
          >
            <el-input
              :disabled="view"
              v-model="form.operatorInfo"
              class="formItem"
              placeholder="创建者信息填创建人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="项目信息："
            label-width="145px"
            prop="projectInfo"
          >
            <el-input
              :disabled="view"
              v-model="form.projectInfo"
              class="formItem"
              placeholder="项目的名称和编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户信息：" label-width="145px" prop="userInfo">
            <el-input
              :disabled="view"
              v-model="form.userInfo"
              class="formItem"
            ></el-input>
          </el-form-item>
          <el-form-item label="支付信息：" label-width="145px" prop="payInfo">
            <el-input
              :disabled="view"
              v-model="form.payInfo"
              class="formItem"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="许可证类型："
            label-width="145px"
            prop="licenseType"
          >
            <el-select
              :disabled="view"
              v-model="form.licenseType"
              class="formItem"
            >
              <el-option label="Free" value="Free"></el-option>
              <el-option label="Demo" value="Demo"></el-option>
              <el-option label="Test" value="Test"></el-option>
              <el-option label="Academic" value="Academic"></el-option>
              <el-option label="Pro" value="Pro"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="支持最低版本："
            label-width="145px"
            prop="minVersion"
            placeholder="输入一个产品版本号，如1.2.3，包含该数值。如不限制，留空。"
          >
            <el-input
              :disabled="view"
              v-model="form.minVersion"
              class="formItem"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="支持最高版本："
            label-width="145px"
            prop="maxVersion"
            placeholder="输入一个产品版本号，如2.0，包含该数值。如不限制，留空。"
          >
            <el-input
              :disabled="view"
              v-model="form.maxVersion"
              class="formItem"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="支持设备类型："
            label-width="145px"
            prop="supportedDeviceType"
          >
            <el-select
              :disabled="view"
              v-model="form.supportedDeviceType"
              class="formItem"
            >
              <el-option label="Unspecified" value="Unspecified"></el-option>
              <el-option label="Device_iOS" value="Device_iOS"></el-option>
              <el-option
                label="Device_Android"
                value="Device_Android"
              ></el-option>
              <el-option
                label="Device_AndroidAuto"
                value="Device_AndroidAuto"
              ></el-option>
              <el-option
                label="Device_Embedded"
                value="Device_Embedded"
              ></el-option>
              <el-option
                label="Device_Windows"
                value="Device_Windows"
              ></el-option>
              <el-option label="Device_Mac" value="Device_Mac"></el-option>
              <el-option
                label="Device_Dongle"
                value="Device_Dongle"
              ></el-option>
              <el-option label="App_iOS" value="App_iOS"></el-option>
              <el-option label="App_Android" value="App_Android"></el-option>
              <el-option label="App_Web" value="App_Web"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="是否过期："
            style="position: relative"
            label-width="145px"
            prop="isOverdue"
          >
            <div class="formItemCheckbox">
              <el-checkbox
                :disabled="view"
                v-model="form.isOverdue"
                style="margin-left: 13px"
                >永不过期</el-checkbox
              >
            </div>
          </el-form-item>
          <el-form-item
            label="过期时间："
            label-width="145px"
            prop="expirationDate"
            :rules="
              form.isOverdue === true
                ? form.expirationDate
                : [
                    {
                      required: true,
                      message: '请选择过期时间',
                      trigger: 'change',
                    },
                  ]
            "
          >
            <el-date-picker
              :disabled="view || this.form.isOverdue === true"
              style="width: 459px"
              v-model="form.expirationDate"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="date"
              :picker-options="pickerOptions"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="权限："
            style="position: relative; margin-bottom: 107px"
            label-width="145px"
            prop="permissionKeys"
          >
            <div class="formItemCheckbox" style="height: 124px; overflow: auto">
              <el-checkbox-group :disabled="view" v-model="form.permissionKeys">
                <el-checkbox
                  style="margin-left: 15px"
                  v-for="item in permissionData"
                  :key="item.key"
                  :value="item.id"
                  :label="item.id"
                  >{{ item.title }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item
            label="离线使用时长(天)："
            label-width="145px"
            prop="offlineUseDays"
          >
            <el-input
              :disabled="view"
              class="formItem"
              v-model="form.offlineUseDays"
              placeholder="单位天，默认输入90天"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="绑定限制："
            style="position: relative"
            label-width="145px"
            prop="bindLimited"
          >
            <div class="formItemCheckbox">
              <el-checkbox
                @change="limitedChange"
                :disabled="view"
                v-model="form.bindLimited"
                style="margin-left: 13px"
                >限制设备与许可证一对一绑定</el-checkbox
              >
            </div>
          </el-form-item>
          <el-form-item
            label="许可证的数量："
            label-width="145px"
            prop="countOfLicense"
          >
            <el-input
              :disabled="view || this.form.bindLimited === false"
              class="formItem"
              v-model="form.countOfLicense"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="mainContent" v-show="view">
          <el-row>
            <el-col :span="5">已激活数量：</el-col>
            <el-col :span="5">{{ this.form.countOfUsedLicenses }}</el-col>
          </el-row>
          <el-row class="contentRow">
            <el-col :span="5">已过期数量：</el-col>
            <el-col :span="5">{{ this.form.countOfExpiredLicenses }}</el-col>
          </el-row>
          <el-row class="contentRow">
            <el-col :span="5">可用数量：</el-col>
            <el-col :span="5">{{ this.form.countOfValidLicenses }}</el-col>
          </el-row>
          <el-row class="contentRow">
            <el-col :span="5">激活码创建日期：</el-col>
            <el-col :span="8">{{ this.form.createTime }}</el-col>
          </el-row>
        </div>
        <div
          v-if="message !== ''"
          class="errorMessage"
          style="margin-top: 18px; margin-bottom: 18px"
        >
          {{ message }}
        </div>
        <el-button
          type="primary"
          class="footerSubmitButton"
          :disabled="view || isDisabled"
          @click="submit('form')"
          >确定</el-button
        >
        <el-button class="footerCancelButton" @click="cancel">取消</el-button>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import myPages from "@/components/Commonpaging/Pagination";
import {
  searchCode,
  createCode,
  disableCode,
  enableCode,
  getProduct,
} from "@/api/jihuomaguanli/jihuoma";
import { queryPermission } from "@/api/quanxianguanli/quanxian";
export default {
  name: "jihuomatable",
  data() {
    return {
      dialogFormVisible: false,
      isDisabled: false,
      total: 0, //默认数据总数
      pageSize: 10, //每页数据条数
      currentPage: 1, //默认开始页面
      view: false,
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      queryParam: {
        enabledOnly: true,
        productName: "",
        keyWord: "",
      },
      form: {
        productName: "",
        keyData: "",
        operatorInfo: "",
        projectInfo: "",
        userInfo: "",
        payInfo: "",
        licenseType: "",
        isOverdue: false,
        minVersion: "",
        maxVersion: "",
        supportedDeviceType: "",
        expirationDate: "",
        permissionKeys: [],
        offlineUseDays: "90",
        bindLimited: true,
        countOfLicense: "1",
      },
      rules: {
        productName: [
          {
            required: true,
            message: "请输入产品名称",
            trigger: "change",
          },
        ],
        operatorInfo: [
          {
            required: true,
            message: "请输入创建者信息",
            trigger: "change",
          },
        ],
        projectInfo: [
          {
            required: true,
            message: "请输入项目信息",
            trigger: "change",
          },
        ],
        licenseType: [
          {
            required: true,
            message: "请选择许可证类型",
            trigger: "change",
          },
        ],
        supportedDeviceType: [
          {
            required: true,
            message: "请选择设备类型",
            trigger: "change",
          },
        ],
        offlineUseDays: [
          {
            required: true,
            message: "请输入离线使用时长",
            trigger: "change",
          },
        ],
        countOfLicense: [
          {
            required: true,
            message: "请输入许可证数量",
            trigger: "change",
          },
        ],
      },
      tableData: [],
      permissionData: [],
      message: "",
      productList: [
      ],
      show:true
    };
  },
  components: {
    myPages,
  },
  mounted() {
    // this.queryList();
    this.queryProduct();
  },
  created() {
  },
  methods: {
    queryProduct(){
      getProduct().then((res) => {
        if(res.status == 0){
          if(res.result.length>0){
            res.result.map(item => {
              item.label = item.productName,
              item.value = item.productId
            })
            this.productList = res.result;
            this.queryParam.productName = res.result[0].value||null
          }else{
            this.show = false
            // this.$message({
            //   message:'此账户无权限!',
            //   duration:0,
            //   type:'error'
            // })
          }
        }
      }).then(() => {
        this.queryList();
      })
    },
    //绑定限制change事件
    limitedChange(val) {
      if (val === true) {
        this.form.countOfLicense = "";
      } else {
        this.form.countOfLicense = "1";
      }
    },

    //根据所选产品名称查找对应的权限
    selectProNameTwo() {
      let formData = new FormData();
      formData.append("pageNum", this.currentPage);
      formData.append("pageSize", 100000);
      formData.append("productName", this.queryParam.productName);
      queryPermission(formData).then((res) => {
        if (res.status === 0) {
          let array = res.result.list;
          array.map((item, index) => {
            this.permissionData.push({
              title: item.name,
              id: item.keyData,
              key: index,
            });
          });
        }
      });
    },
    //创建激活码
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          let activation = {
            productName: this.form.productName,
            keyData: this.form.keyData,
            operatorInfo: this.form.operatorInfo,
            projectInfo: this.form.projectInfo,
            userInfo: this.form.userInfo,
            licenseType: this.form.licenseType,
            isOverdue: this.form.isOverdue,
            minVersion: this.form.minVersion,
            maxVersion: this.form.maxVersion,
            supportedDeviceType: this.form.supportedDeviceType,
            expirationDate: this.form.expirationDate,
            permissionKeys: this.form.permissionKeys,
            offlineUseDays: this.form.offlineUseDays,
            bindLimited: this.form.bindLimited,
            countOfLicense: this.form.countOfLicense,
          };
          createCode(activation).then((res) => {
            console.log(res,'res')
            if (res.status === 0) {
              this.isDisabled = false;
              let keyData = res.result.keyData;
              let supportedDeviceType = res.result.supportedDeviceType;
              let productName = res.result.productName;
              let licenseType = res.result.licenseType;
              let countOfLicense = res.result.countOfLicense;
              let expirationDate = res.result.expirationDate.substring(0,10);
              let arr = [
                "创建激活码成功,该激活码的Key为",
                keyData,
                "支持在" +
                  countOfLicense +
                  "个" +
                  supportedDeviceType +
                  "上激活" +
                  licenseType +
                  "类型的" +
                  productName +
                  "产品。",
                "过期时间："+ expirationDate,
                "请记录该Key并发送给客户，以便开始激活设备。",
                "后续也可通过搜索该Key来追踪激活码的使用情况。",
              ];
              let str = arr.join("<br />");
              this.$confirm(str, {
                confirmButtonText: "确定",
                dangerouslyUseHTMLString: true,
                customClass: "submitContaibner",
              })
                .then(() => {
                  this.sure();
                })
                .catch(() => {});
            } else {
              this.$message({
                message: "创建失败",
                type: "error",
              });
              this.dialogFormVisible = true;
              this.message = res.message;
            }
          });
        }
      });
    },
    //创建成功按钮
    sure() {
      this.$message({
        message: "创建成功",
        type: "success",
      });
      this.dialogFormVisible = false;
      this.queryList();
    },
    //查询激活码列表
    queryList() {
      let formData = new FormData();
      formData.append("pageNum", this.currentPage);
      formData.append("pageSize", this.pageSize);
      formData.append("enabledOnly", this.queryParam.enabledOnly);
      formData.append("productName", this.queryParam.productName);
      formData.append("keyWord", this.queryParam.keyWord);
      searchCode(formData).then((res) => {
        if (res.status === 0) {
          var result = res.result;
          this.currentPage = result.pageNum;
          this.total = result.total;
          this.tableData = result.list;
        }
      });
    },
    //禁用
    ban(row) {
      let arr = [
        "是否禁用激活码'" + row.keyData + "'?",
        "此操作不影响已经激活许可证的使用",
      ];
      let str = arr.join("<br />");
      this.$confirm(str, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        customClass: "messageContainer",
      })
        .then(() => {
          this.endroad(row);
        })
        .catch(() => {});
    },
    //禁用 调用接口
    endroad(row) {
      let formData = new FormData();
      formData.append("id", row.id);
      disableCode(formData).then((res) => {
        if (res.status === 0) {
          this.$message.success("禁用成功");
          this.queryList();
        } else {
          this.$meaages.error("删除失败");
          return;
        }
      });
    },
    //启用按钮
    start(row) {
      let arr = [
        "是否启用激活码'" + row.keyData + "'?",
        "此操作不影响已经激活许可证的使用",
      ];
      let str = arr.join("<br />");
      this.$confirm(str, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        customClass: "messageContainer",
      })
        .then(() => {
          this.startKeyData(row);
        })
        .catch(() => {});
    },
    //启用  调用接口
    startKeyData(row) {
      let formData = new FormData();
      formData.append("id", row.id);
      enableCode(formData).then((res) => {
        if (res.status === 0) {
          this.$message.success("启用成功");
          this.queryList();
        } else {
          this.$message.error("启用失败");
          return;
        }
      });
    },
    //查看
    lookUp(row) {
      this.selectProNameTwo();
      this.dialogFormVisible = true;
      this.view = true;
      this.$nextTick(() => {
        this.form.productName = row.productName;
        this.form.keyData = row.keyData;
        this.form.operatorInfo = row.operatorInfo;
        this.form.projectInfo = row.projectInfo;
        this.form.userInfo = row.userInfo;
        this.form.payInfo = row.payInfo;
        this.form.licenseType = row.licenses[0].type;
        this.form.minVersion = row.minVersion;
        this.form.maxVersion = row.maxVersion;
        this.form.supportedDeviceType = row.supportedDeviceType;
        this.form.expirationDate = row.expirationDate;
        this.form.isOverdue = row.expirationDate !== null ? false : true;
        this.form.permissionKeys = row.permissionKeys;
        this.form.offlineUseDays = row.offlineUseDays;
        this.form.bindLimited = row.bindLimited;
        this.form.countOfLicense = row.countOfLicense;
        this.form.countOfValidLicenses = row.countOfValidLicenses;
        this.form.countOfUsedLicenses = row.countOfUsedLicenses;
        this.form.countOfExpiredLicenses = row.countOfExpiredLicenses;
        this.form.createTime = row.createTime;
      });
    },
    // 每页展示条数
    handleSizeChangeFun(v) {
      this.pageSize = v;
    },
    // 当前页
    handleCurrentChangeFun(v) {
      //页面点击
      this.currentPage = v; //当前页
      this.queryList();
    },
    //创建激活码按钮
    createActivationCode() {
      this.view = false;
      this.permissionData = [];
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.form = {
          productName: this.queryParam.productName,
          permissionKeys: [],
          countOfLicense: "1",
          offlineUseDays: "90",
          bindLimited: true,
        };
        this.selectProNameTwo();
      });
    },
    //清空表单
    resetFields(form) {
      if (this.view == false) {
        this.$nextTick(() => {
          this.$refs[form].resetFields();
        });
      }
    },
    //取消
    cancel() {
      this.dialogFormVisible = false;
      this.message = "";
      this.view = false;
      this.form = {
        productName: "",
        keyData: "",
        operatorInfo: "",
        projectInfo: "",
        userInfo: "",
        licenseType: "",
        isOverdue: false,
        minVersion: "",
        maxVersion: "",
        supportedDeviceType: "",
        expirationDate: "",
        permissionKeys: [],
        offlineUseDays: "",
        bindLimited: false,
        countOfLicense: "1",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: 0 solid #d5d5d5;
  border-radius: 12px;
  position: relative;
}
.viewName {
  font-size: 18px;
  color: #4880ff;
  letter-spacing: 0;
  line-height: 60px;
  font-weight: 500;
  position: absolute;
  left: 23px;
}
.el-button {
  font-size: 14px;
  background: #4379ee;
  position: absolute;
  right: 14px;
  top: 11px;
}
.content {
  background: #ffffff;
  overflow: auto;
  border-radius: 14px;
  margin-top: 10px;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
}
.contentItem {
  position: absolute;
  left: 23px;
  top: 27px;
}
.contentCheckbox {
  position: absolute;
  top: 27px;
  left: 349px;
}
.contentInput {
  position: absolute;
  right: 4px;
  top: 27px;
}
.itemInput {
  width: 408px;
}
.mytablediv {
  margin-top: 95px;
}
.success {
  background: rgba(0, 182, 155, 0.2);
  color: #00b69b;
}
.danger {
  background: rgba(239, 56, 38, 0.2);
  color: #ef3826;
}
.view {
  width: 38px;
  height: 22px;
  border: 1px solid #4379ee;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  cursor: pointer;
}
.ban {
  width: 38px;
  height: 22px;
  border: 1px solid #4379ee;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  margin-left: 5px;
  cursor: pointer;
}
.disabledBan {
  width: 38px;
  height: 22px;
  border: 1px solid #878787;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  margin-left: 5px;
  pointer-events: none;
  color: #878787;
  cursor: pointer;
}
.start {
  width: 38px;
  height: 22px;
  border: 1px solid #4379ee;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  margin-left: 5px;
  cursor: pointer;
}
.disabledStart {
  width: 38px;
  height: 22px;
  border: 1px solid #878787;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
  margin-left: 5px;
  pointer-events: none;
  color: #878787;
  cursor: pointer;
}
.el-dialog {
  width: 663px !important;
  border-radius: 26px;
}
.formItem {
  width: 459px;
}
.formItemCheckbox {
  width: 459px;
  position: absolute;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 40px;
}
.el-pagination {
  float: left !important;
}
.mainContent {
  width: 570px;
  height: 138px;
  background: #eaeaea;
  border-radius: 8px;
  margin-left: 20px;
  padding-left: 12px;
  padding-top: 13px;
  color: #202224;
}
.contentRow {
  margin-top: 18px;
}
.el-checkbox {
  display: block;
}
.footerSubmitButton {
  position: relative;
  bottom: 20px;
  left: 100px;
  width: 130px;
  background: #4880ff;
  border-radius: 6px;
}
.footerCancelButton {
  position: relative;
  bottom: 20px;
  left: 250px;
  width: 130px;
  border-radius: 6px;
  background: #d8d8d8;
  color: #fff;
}
.footerCancelButton:hover {
  background: #d8d8d8;
  color: #fff;
}
.errorMessage {
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
  color: #fa5555;
}
</style>
<style lang='scss'>
.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #4379ee;
  border-radius: 8px;
}
.dialogContainer .el-dialog {
  width: 663px;
}
.dialogContainer .el-dialog__headerbtn {
  background: url("../../../assets/icon_error.png") !important;
  background-size: 100% 100%;
  width: 21px;
  height: 21px;
}
.dialogContainer .el-icon-close {
  display: none !important;
}
.contentItem .el-form-item__label {
  font-size: 16px !important;
  color: #404040 !important;
  letter-spacing: 0;
  font-weight: 400;
}
.messageContainer {
  width: 663px !important;
  height: 315px !important;
  position: relative;
  border-radius: 26px;
}
.messageContainer .el-message-box__message {
  margin-top: 100px;
  line-height: 315px;
  text-align: center;
  font-size: 16px;
  color: #202224;
  letter-spacing: 0;
}
.messageContainer .el-message-box__btns .el-button--default {
  position: absolute;
  bottom: 26px;
  right: 126px;
  width: 130px;
  height: 35.9px;
  background: #d8d8d8;
  border-radius: 6px;
  color: #fff;
}
.messageContainer .el-message-box__btns .el-button--default:hover {
  position: absolute;
  bottom: 26px;
  right: 126px;
  width: 130px;
  height: 35.9px;
  background: #d8d8d8;
  border-radius: 6px;
  color: #fff;
}
.messageContainer .el-message-box__btns .el-button--primary {
  position: absolute;
  bottom: 26px;
  left: 146px;
  background: #4880ff;
  border-radius: 6px;
}
.messageContainer .el-message-box__btns .el-button--primary:hover {
  position: absolute;
  bottom: 26px;
  left: 146px;
  background: #4880ff;
  border-radius: 6px;
}
.messageContainer .el-message-box__headerbtn {
  background: url("../../../assets/icon_error.png") !important;
  background-size: 100% 100%;
  width: 21px !important;
  height: 21px !important;
}
.messageContainer .el-icon-close {
  display: none !important;
}
.messageContainer .el-message-box__btns .el-button--default {
  position: absolute;
  bottom: 26px;
  right: 126px;
  width: 130px;
  height: 35.9px;
}
.messageContainer .el-message-box__btns .el-button--primary {
  position: absolute;
  bottom: 26px;
  left: 146px;
  background: #4880ff;
}
.submitContaibner {
  width: 663px !important;
  height: 315px !important;
  position: relative;
  border-radius: 26px;
}
.submitContaibner .el-message-box__message {
  line-height: 315px;
  margin-top: 54px;
  text-align: center;
  font-size: 16px;
  color: #202224;
  letter-spacing: 0;
}
.submitContaibner .el-message-box__btns .el-button--default {
  position: absolute;
  bottom: 26px;
  right: 126px;
  width: 130px;
  height: 35.9px;
  display: none;
}
.submitContaibner .el-message-box__btns .el-button--primary {
  position: absolute;
  bottom: 26px;
  left: 250px;
  display: block;
  background: #4880ff;
}
</style>